<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link
              class="navbar-brand"
              to="/"
            >
              <span class="brand-logo">
                <b-img
                  :src="appLogoImage"
                  alt="logo"
                />
              </span>
              <h2 class="brand-text">
                {{ appName }}
              </h2>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <vertical-nav-menu-items
        v-if="session.user"
        :key="session.domain.sid"
        :items="items"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->

    <cbs-debug :context="this" />
  </div>
</template>

<script>
import navMenuItems from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import useCubus from '@/cubus/services/useCubus'
import cubusBlankMenu from '@/cubus/menu/cubus_blank_menu'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import useJwt from '@/cubus/jwt/useJwt'
import { mapState } from 'vuex'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useCbsVerticalNavMenu from './useCbsVerticalNavMenu'

export default {
  components: {
    CbsDebug,
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useCbsVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    }
  },
  data() {
    return {
      session: {
        status: 'empty',
        session: {},
        error: '',
      },
      userMenu: [],
      userMenu_v0: [
        {
          title: 'Справочники',
          icon: 'MenuIcon',
          roleid: 0,
          children: [
            {
              title: 'Статьи БДР',
              route: '',
              roleid: 0,
              objectid: 0,
              children: null,
            },
            {
              title: 'Статьи Бюджетов',
              children: [
                {
                  title: 'Статьи Доходов',
                  route: null,
                },
                {
                  title: 'Статьи Расходов',
                  route: null,
                },
              ],
            },
          ],
        },
      ],
      isSession: false,
      divider1: { header: '. . . . . . . . . . . . . . . . . . . . . . . . . . . . .' },
      divider2: { header: '. . . . . . . . . . . . . . . . . . . . . . . . . . . .' },
      divider3: { header: '. . . . . . . . . . . . . . . . . . . . . . . . . . .' },
      defaultMenu: [
        {
          title: 'Dashboard',
          route: 'dashboard-cubus',
          icon: 'HomeIcon',
        },
        {
          title: 'Domains',
          route: 'cubus-domains',
          icon: 'LayersIcon',
        },
      ],
      navigationMenu: [
        {
          title: 'NAVIGATION',
          icon: 'NavigationIcon',
          children: [
            {
              title: 'Objects',
              route: 'cubus-objects',
              icon: 'BookIcon',
              hotkeys: ['ctrl+o', 'ctrl+щ'],
            },
            {
              title: 'References',
              route: 'cubus-refs',
              icon: 'ListIcon',
              hotkeys: ['ctrl+r', 'ctrl+к'],
            },
            {
              title: 'Data Tables',
              route: 'cubus-tables',
              icon: 'LayoutIcon',
              hotkeys: ['Ctrl+T', 'ctrl+е'],
            },
            {
              title: 'Cubes',
              route: 'cubus-cubes',
              icon: 'BoxIcon',
              hotkeys: ['Ctrl+C'],
            },
            {
              title: 'Mounts',
              route: 'cubus-mounts',
              icon: 'GridIcon',
              hotkeys: ['Ctrl+M'],
            },
            {
              title: 'Procedures',
              route: 'cubus-procedures',
              icon: 'PlayIcon',
              hotkeys: ['Ctrl+P'],
            },
            {
              title: 'Reports',
              route: 'cubus-reports',
              icon: 'FileTextIcon',
              hotkeys: ['Ctrl+R'],
            },
            {
              title: 'Imports',
              route: 'cubus-imports',
              icon: 'UploadCloudIcon',
              hotkeys: ['Ctrl+I'],
            },
          ],
        },
      ],
    }
  },
  computed: {
    ...mapState({ storeSession: state => state['cubus-store'].session }),
    items() {
      if (this.session && this.session.user) {
        if (this.session.user.role.sid === 'dev' || this.session.user.role.sid === 'root') return this.rootMenu()
        return this.commonMenu()
      }
      return cubusBlankMenu
    },
  },
  watch: {
    storeSession(newValue, oldValue) {
      // console.log('Значение изменилось', newValue, oldValue)
      this.init()
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      // this.initSession()
      this.initSession2()
    },
    initSession2() {
      this.getSession2()
    },
    getSession2() {
      const s = useCubus.getSession()
      if (s !== null) {
        this.session = s
        this.session.status = 'ready'
        this.isSession = true
        this.loadMenu()
        // console.log('CbsVerticalNavMenu init : session ready', this.session)
      } else {
        this.delaySession2()
      }
    },
    delaySession2() { setTimeout(() => { this.getSession2() }, 500) },
    initSession() {
      const s = useCubus.whoami()
      // console.log('session ready', s)
      if (s.status === 'loading') {
        this.delaySession()
      }
      this.session = s
    },
    delaySession() {
      setTimeout(() => { this.getSession() }, 500)
    },
    getSession() {
      const s = useCubus.whoami()
      // console.log('session', s)
      if (s.status === 'loading') {
        this.delaySession()
      } else {
        this.session = s
      }
    },
    loadMenu() {
      useJwt.query({ query: { method: 'usermenu', mode: 'sync', param: {} } })
        .then(response => {
          // console.log('menu response', response)
          if (response.data.result) {
            this.userMenu = response.data.result.menus
          } else {
            this.userMenu = []
          }
          // console.log('userMenu', this.userMenu)
        })
        .catch(error => {
          // console.log('error', error)
        })
    },
    rootMenu() {
      return [...this.defaultMenu, this.divider1, ...this.userMenu, this.divider2, ...this.navigationMenu, this.divider3, ...navMenuItems]
    },
    commonMenu() {
      return [...this.defaultMenu, this.divider1, ...this.userMenu, this.divider2, ...this.navigationMenu]
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
